// src/Projects.js
import React from "react";
import "./Projects.css"; // Ensure you have this CSS file for styling

function Projects() {
  // Example project data
  const projects = [
    {
      title: "Reteti Elephant Sanctuary",
      description:
        "Worked with a team to fix Reteti's database system to connect to their frontend and accurately save all donations, bottle purchases, elephant adoptions and other inputs into Salesforce. Also implemented these connections for their sister company, The Sarara Foundation.",
      imageUrl: "/reteti.png", // Replace with your image path
      link: "https://www.reteti.org/",
      //https://thecatapi.com if i want to link the cat stuff
    },
    {
      title: "DogWorld",
      description:
        "A multiplayer game where a user controls a dog using instruction blocks to complete levels and has a sandbox mode where users can create, save, and play their own levels. Integrates a weather API, uses AWS for the multiplayer connection, and has an arduino bluetooth dog connection that is controlled by the instruction blocks as well. All done with Java.",
      imageUrl: "/dogWorld.png", // Replace with your image path
      link: "https://github.com/mollysandler/DogWorld/",
    },
    // {
    //   title: "OpenRecipes",
    //   description:
    //     "Full stack project to help college students learn new recipes and buy the right ingredients. I created the API for this project and connected it to the frontend. ",
    //   imageUrl: "/path/to/project-two-image.jpg", // Replace with your image path
    //   link: "https://github.com/AlfredMadere/open-recipes",
    // },
    {
      title: "TheCatAPI generator",
      description:
        "Generates a random cat name, image, characteristics, and wiki link upon render using TheCatAPI. This was my first experience with APIs! Hosted with BlueHost. ",
      imageUrl: "/thecatapi.png", // Replace with your image path
      link: "https://mollymakingthings.com/catgen/",
      //https://thecatapi.com if i want to link the cat stuff
    },
  ];

  return (
    <div className="projects">
      <h1>My Projects</h1>
      <div className="projects-list">
        {projects.map((project, index) => (
          <div key={index} className="project-item">
            <img
              src={project.imageUrl}
              alt={project.title}
              className="project-image"
            />
            <div className="project-details">
              <h2>{project.title}</h2>
              <p>{project.description}</p>
              <a href={project.link} target="_blank" rel="noopener noreferrer">
                Learn More
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Projects;
