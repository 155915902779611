import React from "react";
import "./About.css";

function About() {
  const images = [
    "./About/arches.JPG",
    "./About/friends.jpg",
    "./About/friends.jpg",
    "./About/friends.jpg",
    "./About/frisbee.JPEG",
    "./About/colorado.JPG",
    "./About/friends.jpg",
    "./About/crochet.jpg",
  ];

  return (
    <div className="about">
      {/* Hero Section */}
      <section className="about-hero">
        <h1>About Me</h1>
      </section>

      {/* Work and Interests */}
      <div className="about-content">
        <div className="about-card work">
          <h2>Online</h2>
          <ul>
            <li>
              <strong>Exclusive Networks:</strong> I work in software
              development at Exclusive Networks, focusing on creating new
              cybersecurity tools to make cyber safety more accessible to the
              average person. I enjoy the challenges and learning opportunities
              that come with my profession.
            </li>
            <li>
              <strong>Social Media:</strong> I am an avid user of social media,
              and love using my platforms to promote positiivity and honesty
              online! I have worked with brands such as
              <a href="https://www.instagram.com/bragg/">
                {" "}
                Bragg Live Foods
              </a>{" "}
              to both run their social medias and create content for them as an
              influencer.
            </li>
          </ul>
        </div>

        <div className="about-card offline">
          <h2>Offline</h2>
          <ul>
            <li>
              <strong>Ultimate Frisbee:</strong> I have been playing ultimate
              frisbee since middle school and currently play for Cal Poly
              Women's Ultimate, SLO Motion, and Santa Barbara mixed ultimate,
              Robot.
            </li>
            <li>
              <strong>Crochet:</strong> I love to crochet, and run an Etsy shop
              for my pieces, which you can check out{" "}
              <a href="https://www.etsy.com/shop/mollymakingthings">here!</a>
            </li>
            <li>
              <strong>Outdoors:</strong> I love spending time in nature and am
              working towards getting my Adirondack 46 right now.
            </li>
          </ul>
        </div>
      </div>

      {/* Image Gallery */}
      <h2 className="gallery-title">In The Wild</h2>
      <div className="image-gallery">
        {images.map((image, index) => (
          <div className="gallery-item" key={index}>
            <img
              src={image}
              alt={`Gallery ${index + 1}`}
              className="gallery-image"
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default About;
