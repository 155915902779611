import React from "react";
import "./Home.css";
import { NavLink } from "react-router-dom";
import { scroller } from "react-scroll";

function Home() {
  const scrollToSection = (section) => {
    scroller.scrollTo(section, {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };
  return (
    <div className="home-container">
      <div className="home-content">
        <h1>Welcome to Molly's World</h1>
        <p>
          I'm Molly, a web developer with a passion for building interactive and
          dynamic web experiences. I'm currently attending Cal Poly SLO as a
          fourth year Software Engineering major with a minor in
          Entreprenurship. I have experience with Python, Java, SQL, Javascript,
          React, Git, and C. Explore my projects, learn about my journey, and
          feel free to get in touch!
        </p>
        <div className="image-and-cta">
          <img src="/molly_headshot.jpeg" alt="Molly" className="profile-pic" />
          <div className="cta-buttons">
            <NavLink
              to="/"
              exact
              className={({ isActive }) => (isActive ? "active" : "")}
              onClick={() => scrollToSection("about")}
            >
              About Me
            </NavLink>

            <NavLink
              to="/"
              exact
              className={({ isActive }) => (isActive ? "active" : "")}
              onClick={() => scrollToSection("projects")}
            >
              View Projects
            </NavLink>
            <NavLink
              to="/"
              exact
              className={({ isActive }) => (isActive ? "active" : "")}
              onClick={() => scrollToSection("contact")}
            >
              Get In Touch
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
