// src/Contact.js
import React from "react";
import "./Contact.css"; // Ensure you have this CSS file for styling
import {
  AiOutlineLinkedin,
  AiOutlineGithub,
  AiOutlineInstagram,
} from "react-icons/ai";

function Contact() {
  // Static URL or path to the image

  return (
    <div className="contact">
      <h1>Contact Me</h1>
      <div className="contact-content">
        <div className="contact-about">
          <div display="flex" justifyContext="center" alignItems="center">
            <div className="social-media">
              <a
                href="https://www.linkedin.com/in/molly-sandler/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiOutlineLinkedin size={30} />
              </a>
              <a
                href="https://www.instagram.com/mollyelaine_/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiOutlineInstagram size={30} />
              </a>
              <a
                href="https://github.com/mollysandler"
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiOutlineGithub size={30} />
              </a>
            </div>
          </div>
        </div>
      </div>

      <form className="contact-form">
        <label htmlFor="name">Name</label>
        <input type="text" id="name" name="name" required />

        <label htmlFor="email">Email</label>
        <input type="email" id="email" name="email" required />

        <label htmlFor="message">Message</label>
        <textarea id="message" name="message" rows="5" required></textarea>

        <button type="submit">Send Message</button>
      </form>
    </div>
  );
}

export default Contact;
